<template>
  <b-overlay :show="isLoading">
    <div v-for="erreur in Object.keys(errors)">
      <div v-for="message in Object.values(errors[erreur])">
        <b-alert show style="padding: 5px" variant="danger">{{ erreur }} : {{ message[0] }}</b-alert>

      </div>
    </div>
    <div>

      <div class="header-detail">

        <b-avatar v-if="form.photo"
                  :src="$store.getters['general/apiUrl']+'/'+form.photo"
                  size="70px"
        />
      </div>
      <form-wizard
          :subtitle="null"
          :title="null"
          back-button-text="Precedent"
          class="mb-3 formUsers"
          color="rgb(40, 167, 69)"
          finish-button-text="Soumettre"
          next-button-text="Suivant"
          shape="circle"
          stepSize="sm"
          @on-complete="createLine"
      >

        <tab-content
            :before-change="validationForm"
            title="Information personnel"
        >

          <div class="row">

            <div :style="{ display: ishidden('typeseffectif_id') }" class="form-group col-sm-12">
              <label>typesclients </label>
              <CustomSelect
                  :key="form.typeseffectifs"
                  :columnDefs="['id','libelle']"
                  :oldValue="form.directions"
                  :renderCallBack="(data)=>`${data.libelle}`"
                  :selectCallBack="(data)=>form.typeseffectif_id=data.id"
                  :url="`${axios.defaults.baseURL}/api/typeseffectifs-Aggrid1`"
                  filter-key=""
                  filter-value=""
              />
              <div v-if="errors.typeseffectif_id" class="invalid-feedback">
                <template v-for=" error in errors.typeseffectif_id"> {{ error[0] }}</template>

              </div>
            </div>
            <div :style="{display: ishidden('nom')}" class="form-group col-sm-12">
              <label>nom </label>
              <input v-model="form.nom" :class="errors.nom?'form-control is-invalid':'form-control'"
                     type="text">

              <div v-if="errors.nom" class="invalid-feedback">
                <template v-for=" error in errors.nom"> {{ error[0] }}</template>

              </div>
            </div>
            <div :style="{display: ishidden('prenom')}" class="form-group col-sm-12">
              <label>prenom </label>
              <input v-model="form.prenom" :class="errors.prenom?'form-control is-invalid':'form-control'"
                     type="text">

              <div v-if="errors.prenom" class="invalid-feedback">
                <template v-for=" error in errors.prenom"> {{ error[0] }}</template>

              </div>
            </div>
            <div :style="{display: ishidden('nationalite_id')}" class="form-group col-sm-12">
              <label>nationalites </label>
              <CustomSelect
                  :key="form.nationalites"
                  :columnDefs="['id','libelle']"
                  :oldValue="form.nationalites"
                  :renderCallBack="(data)=>`${data.libelle}`"
                  :selectCallBack="(data)=>form.nationalite_id=data.id"
                  :url="`${axios.defaults.baseURL}/api/nationalites-Aggrid1`"
                  filter-key=""
                  filter-value=""
              />
              <div v-if="errors.nationalite_id" class="invalid-feedback">
                <template v-for=" error in errors.nationalite_id"> {{ error[0] }}</template>

              </div>
            </div>
            <div :style="{display: ishidden('num_badge')}" class="form-group col-sm-12">
              <label>numero badge</label>
              <input v-model="form.num_badge"
                     :class="errors.num_badge?'form-control is-invalid':'form-control'"
                     type="text">

              <div v-if="errors.num_badge" class="invalid-feedback">
                <template v-for=" error in errors.num_badge"> {{ error[0] }}</template>

              </div>
            </div>
          </div>

        </tab-content>

        <!-- <tab-content
            :before-change="validationForm"
            title="Badge"
        >

            <div class="row">

                <div :style="{display: ishidden('photo')}" class="form-group col-sm-12">
                    <label>photo </label>

                    <PhotoSgs v-model="form.photo"></PhotoSgs>
                    <div v-if="errors.photo" class="invalid-feedback">
                        <template v-for=" error in errors.photo"> {{ error[0] }}</template>
                    </div>
                </div>
            </div>

            <div class="blockBadge">
                <div style="text-align: center">

                    <h5>Apercu du badge {{ $domaine }}</h5>
                </div>

                <div class="row" style="display:flex;justify-content:center">
                    {{ $domaine }}
                    <CAPrintBadge v-if="$domaine=='c.a'" :agent="form"/>
                    <SGSPrintBadge v-else-if="$domaine=='sgs'" :agent="form"/>
                    <PrintBadge v-else :agent="form"/>
                </div>

            </div>
        </tab-content> -->


      </form-wizard>
    </div>
  </b-overlay>
</template>

<script>
import {mapGetters} from 'vuex';


import 'vue-form-wizard/dist/vue-form-wizard.min.css'

export default {
  name: 'CreateAgents',
  components: {
    VSelect: () => import("vue-select"),
     CustomSelect: () => import("@/components/CustomSelect.vue"),
     Files: () => import("@/components/Files.vue"),
     PhotoSgs: () => import("@/components/PhotoSgs.vue"),
     CAPrintBadge: () => import("@/components/c.a.PrintBadge.vue"),
     SGSPrintBadge: () => import("@/components/sgs.PrintBadge.vue"),
     PrintBadge: () => import("@/components/PrintBadge.vue"),
    FormWizard: async () => {
      await import('vue-form-wizard/dist/vue-form-wizard.min.css');
      return import("vue-form-wizard").then(module => module.FormWizard);
    },
    TabContent: async () => {
      await import('vue-form-wizard/dist/vue-form-wizard.min.css');
      return import("vue-form-wizard").then(module => module.TabContent);
    },
     ValidationObserver: () => import("vee-validate").then(module => module.ValidationObserver),
     ValidationProvider: () => import("vee-validate").then(module => module.ValidationProvider),
  },
  props: [
    'gridApi',
    'modalFormId',
    'actifsData',
    'balisesData',
    'categoriesData',
    'contratsData',
    'directionsData',
    'echelonsData',
    'factionsData',
    'fonctionsData',
    'matrimonialesData',
    'nationalitesData',
    'onlinesData',
    'postesData',
    'sexesData',
    'sitesData',
    'situationsData',
    'typesData',
    'usersData',
    'villesData',
    'zonesData',
    'champsAfficher',

  ],
  data() {
    return {
      errors: [],
      isLoading: false,
      form: {

        id: "",

        name: "",

        nom: "",

        prenom: "",

        matricule: "",

        num_badge: "",

        date_naissance: "",

        num_cnss: "",

        num_cnamgs: "",

        telephone1: "",

        telephone2: "",

        photo: "",

        date_embauche: "",

        download_date: "",

        actif_id: 2,

        nationalite_id: "",

        contrat_id: "",

        direction_id: "",

        categorie_id: "",

        echelon_id: "",

        sexe_id: "",

        matrimoniale_id: "",

        poste_id: "",

        ville_id: "",

        zone_id: "",

        site_id: "",

        situation_id: "",

        balise_id: "",

        fonction_id: "",

        user_id: "",

        email: "",

        email_verified_at: "",

        password: "",

        emp_code: "",

        nombre_enfant: "",

        num_dossier: "",

        online_id: "",

        type: 2,

        faction_id: "",

        remember_token: "",

        extra_attributes: "",

        created_at: "",

        updated_at: "",

        deleted_at: "",
      }
    }
  },
  computed: {
    ...mapGetters('app', ['subDomain', 'domain']),
    $routeData: function () {
      let router = {meta: {}};
      try {
        if (typeof window.routeData != 'undefined') {
          router = window.routeData
        }
      } catch (e) {
      }

      return router;
    },
    $domaine: function () {
      let domaine = 'supervizr';
      try {
        domaine = window.domaine
      } catch (e) {
      }

      return domaine;
    },
    taille: function () {
      let result = 'col-sm-12'
      if (this.filtre) {
        result = 'col-sm-9'
      }
      return result
    },
  },
  methods: {
    createLine() {
      this.isLoading = true
      if (this.$route.meta.isOne) {
        this.form.type = 3
      }
      this.axios.post('/api/users', this.form).then(response => {
        this.isLoading = false
        this.resetForm()
        this.gridApi.applyServerSideTransaction({
          add: [
            response.data
          ],
        });
        this.gridApi.refreshServerSide()
        this.$bvModal.hide(this.modalFormId)
        this.$emit('close')
        this.$toast.success('Operation effectuer avec succes')
        console.log(response.data)
      }).catch(error => {
        this.isLoading = false
        this.errors = error.response.data.errors

        this.$toast.error('Erreur survenue lors de l\'enregistrement')
      })
    },
    resetForm() {
      this.form = {
        id: "",
        name: "",
        nom: "",
        prenom: "",
        matricule: "",
        num_badge: "",
        date_naissance: "",
        num_cnss: "",
        num_cnamgs: "",
        telephone1: "",
        telephone2: "",
        photo: "",
        date_embauche: "",
        download_date: "",
        actif_id: "",
        nationalite_id: "",
        contrat_id: "",
        direction_id: "",
        categorie_id: "",
        echelon_id: "",
        sexe_id: "",
        matrimoniale_id: "",
        poste_id: "",
        ville_id: "",
        zone_id: "",
        site_id: "",
        situation_id: "",
        balise_id: "",
        fonction_id: "",
        user_id: "",
        email: "",
        email_verified_at: "",
        password: "",
        emp_code: "",
        nombre_enfant: "",
        num_dossier: "",
        online_id: "",
        type: "",
        faction_id: "",
        remember_token: "",
        extra_attributes: "",
        created_at: "",
        updated_at: "",
        deleted_at: "",
      }
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        resolve(true)
        // this.$refs.accountRules.validate().then(success => {
        //   if (success) {
        //     resolve(true)
        //   } else {
        //     reject()
        //   }
        // })
      })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormSocial() {
      return new Promise((resolve, reject) => {
        this.$refs.socialRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    ishidden(fieldName) {

      return false;
    },
  },

}
</script>
<style>
.blockBadge {
  padding: 10px;
  border: dashed;
  border-radius: 5px;
}
</style>
